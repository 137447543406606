import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { Tag, Input, Icon } from 'antd';
import { TweenOneGroup } from 'rc-tween-one';

// Import actions
import { setUserProfile } from '../../redux/actions/userActions';

const StackList = ({ user, setUserProfile }) => {
  const [tags, setTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const saveInputRef = useRef(null);

  useEffect(() => {
    if (user.stack && user.stack.length) setTags(user.stack);
  }, [user.stack]);

  useEffect(() => {
    if (inputVisible === true) saveInputRef.current.focus();
  }, [inputVisible]);

  const saveChanges = newTags => {
    if (user && user.uid) setUserProfile({ uid: user.uid, stack: newTags });
  };

  const showInput = () => setInputVisible(true);

  const handleInputChange = e => {
    setInputValue(e.target.value);
  };
  const handleClose = removedTag => {
    const newTags = tags.filter(tag => tag !== removedTag);
    setTags(newTags);
    saveChanges(newTags);
  };

  const handleInputConfirm = () => {
    let newTags = tags;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      newTags = [...tags, inputValue];
    }
    setTags(newTags);
    saveChanges(newTags);
    setInputValue('');
  };

  const tagChild = tags.map(tag => {
    const tagElem = (
      <Tag
        closable
        onClose={e => {
          e.preventDefault();
          handleClose(tag);
        }}
      >
        {tag}
      </Tag>
    );
    return (
      <span key={tag} style={{ display: 'inline-block' }}>
        {tagElem}
      </span>
    );
  });

  return (
    <div>
      <div style={{ marginBottom: 16 }}>
        <TweenOneGroup
          enter={{
            scale: 0.8,
            opacity: 0,
            type: 'from',
            duration: 100,
            onComplete: e => {
              e.target.style = '';
            },
          }}
          leave={{ opacity: 0, width: 0, scale: 0, duration: 200 }}
          appear={false}
        >
          {tagChild}
        </TweenOneGroup>
      </div>
      {inputVisible && (
        <Input
          ref={saveInputRef}
          type="text"
          size="small"
          style={{ width: 78 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag
          onClick={showInput}
          style={{ background: '#fff', borderStyle: 'dashed' }}
        >
          <Icon type="plus" /> Add Stack
        </Tag>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  { setUserProfile }
)(StackList);
