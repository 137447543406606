// Import pages

import HomePage from '../pages/HomePage';
import AboutPage from '../pages/AboutPage';
import ProfilePage from '../pages/ProfilePage';
import JoinPage from '../pages/JointPage';
import LoginPage from '../pages/LoginPage';
import SettingsPage from '../pages/SettingsPage';
import DevelopersPage from '../pages/DevelopersPage';

export default [
  {
    to: '/',
    name: 'Home',
    exact: true,
    hasMenu: true,
    userMenu: false,
    restrictedRoute: false,
    enabled: true,
    component: HomePage,
  },
  {
    to: '/about',
    name: 'About',
    exact: true,
    hasMenu: true,
    userMenu: false,
    restrictedRoute: false,
    enabled: true,
    component: AboutPage,
  },
  {
    to: '/profile',
    name: 'Profile',
    exact: true,
    hasMenu: false,
    userMenu: true,
    restrictedRoute: true,
    enabled: true,
    component: ProfilePage,
  },
  {
    to: '/developers',
    name: 'Developers',
    exact: true,
    hasMenu: true,
    userMenu: false,
    restrictedRoute: true,
    enabled: true,
    component: DevelopersPage,
  },
  {
    to: '/join',
    name: 'Join',
    exact: true,
    hasMenu: false,
    userMenu: false,
    restrictedRoute: false,
    enabled: true,
    component: JoinPage,
  },
  {
    to: '/login',
    name: 'Login',
    exact: true,
    hasMenu: false,
    userMenu: false,
    restrictedRoute: false,
    enabled: true,
    component: LoginPage,
  },
  {
    to: '/settings',
    name: 'Settings',
    exact: true,
    hasMenu: false,
    userMenu: true,
    restrictedRoute: true,
    enabled: true,
    component: SettingsPage,
  },
];
