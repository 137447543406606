/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import { Card, Button, Typography, Divider } from 'antd';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

import { auth } from '../../data/firebase';

// Import actions.
import { userSignedOut } from '../../redux/actions/userActions';

const { Title, Paragraph } = Typography;

const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};
const Signin = ({ history, user, authenticating, userSignedOut }) => {
  return (
    <Card
      style={{ textAlign: 'center' }}
      bordered={false}
      loading={authenticating}
    >
      {!isEmpty(user) && (
        <div>
          <Typography>
            <Title level={3}>{user.displayName}</Title>
            <Paragraph>Welcome to Ethiopian Developers Network</Paragraph>
            <Paragraph />
          </Typography>

          <Button
            size="large"
            type="primary"
            onClick={() => history.push('/profile')}
          >
            Complete your profile
          </Button>
          <Divider> OR </Divider>
          <div>
            <a
              href=""
              onClick={() => {
                userSignedOut();
                auth().signOut();
                history.push('/join');
              }}
            >
              Sign out
            </a>
          </div>
        </div>
      )}

      {isEmpty(user) && (
        <div>
          <Typography>
            <Title>Register / Login in</Title>
          </Typography>
          <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth()} />
        </div>
      )}
    </Card>
  );
};

const mapStateToProps = state => ({
  authenticating: state.auth.authenticating,
  user: state.user,
});

export default withRouter(
  connect(
    mapStateToProps,
    { userSignedOut }
  )(Signin)
);
