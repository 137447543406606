import {
  USER_AUTHENTICATING,
  USER_AUTHENTICATION_FAILED,
  USER_AUTHENTICATED,
  USER_SIGNED_IN,
  USER_SIGNING_OUT,
  USER_SIGNED_OUT,
  USER_EMAIL_VERIFICATION_LINK_SENT,
  USER_EMAIL_VERIFIED,
  USER_RESET_CODE_VERIFIED,
  USER_PASSWORD_RESET_CONFIRMED,
  USER_PASSWORD_RESET_SENT,
  USER_SIGNED_UP,
  USER_PROFILE_FETCHED,
  USER_PROFILE_UPDATED,
} from './types';

import { auth, firestore } from '../../data/firebase';
import { buildDocumentObject } from '../../data/firebase/helpers';

export const userSigningOut = () => ({
  type: USER_SIGNING_OUT,
});

export const userSignedOut = () => ({
  type: USER_SIGNED_OUT,
});

export const userAuthenticating = () => ({
  type: USER_AUTHENTICATING,
});

export const userProfileFetched = user => ({
  type: USER_PROFILE_FETCHED,
  user,
});

export const userProfileUpdated = user => ({
  type: USER_PROFILE_UPDATED,
  user,
});

export const userSignedIn = (
  { uid, email, displayName, phoneNumber, photoURL },
  claims
) => ({
  type: USER_SIGNED_IN,
  user: {
    uid,
    email,
    displayName,
    phoneNumber,
    photoURL,
    claims,
  },
});

export const userAuthenticationFailed = () => ({
  type: USER_AUTHENTICATION_FAILED,
});

export const userAuthenticated = () => ({
  type: USER_AUTHENTICATED,
});

export const emailVerificationEmailSent = () => ({
  type: USER_EMAIL_VERIFICATION_LINK_SENT,
});

export const emailVerified = () => ({
  type: USER_EMAIL_VERIFIED,
});

export const resetCodeVerified = () => ({
  type: USER_RESET_CODE_VERIFIED,
});

export const passwordResetConfirmed = () => ({
  type: USER_PASSWORD_RESET_CONFIRMED,
});

export const resetPasswordSent = () => ({
  type: USER_PASSWORD_RESET_SENT,
});

export const userSignedUp = user => ({
  type: USER_SIGNED_UP,
  user,
});

export const startAuthListener = () => dispatch => {
  dispatch(userAuthenticating());
  return auth().onAuthStateChanged(
    user => {
      if (user && user.uid) {
        dispatch(userSignedIn({}));
        dispatch(fetchUserProfile(user.uid));
        dispatch(userAuthenticated());
      } else {
        dispatch(userAuthenticationFailed());
      }
    },
    error => console.log(error.message)
  );
};

export const signUpWithEmailAndPassword = (
  email,
  password
) => async dispatch => {
  try {
    const user = await auth().createUserWithEmailAndPassword(email, password);
    return dispatch(userSignedUp(user));
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserProfile = uid => async dispatch => {
  try {
    const doc = await firestore()
      .doc(`users/${uid}`)
      .get();
    if (doc.exists) {
      const user = buildDocumentObject(doc);
      return dispatch(userProfileFetched(user));
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserProfile = userData => async dispatch => {
  try {
    await firestore()
      .doc(`users/${userData.uid}`)
      .update(userData);

    return dispatch(userProfileUpdated(userData));
  } catch (error) {
    console.log(error);
  }
};

export const setUserProfile = userData => async dispatch => {
  try {
    return firestore()
      .doc(`users/${userData.uid}`)
      .set(userData, { merge: true });
  } catch (error) {
    console.log(error);
  }
};
