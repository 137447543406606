import React from 'react';
import { connect } from 'react-redux';
import { Row, Col, Divider } from 'antd';

import ProfileForm from '../forms/ProfileForm';
import UserAvatar from '../users/UserAvatar';
import StackList from './StackList';

const Profile = ({ user }) => {
  return (
    <Row gutter={24}>
      <Col lg={6} md={24}>
        <UserAvatar user={user} />
        <Divider />
        <h3>DEV STACK</h3>
        <StackList />
      </Col>
      <Col lg={18} md={24}>
        <ProfileForm user={user} />
      </Col>
    </Row>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(Profile);
