import React from 'react';

import FullPageLayout from '../layout/FullPageLayout';

const LoginPage = props => {
  return (
    <FullPageLayout>
      {/* <Signin />
       */}
      <div>Log In</div>
    </FullPageLayout>
  );
};

export default LoginPage;
