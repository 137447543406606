/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button, Card, Col, Row, message } from 'antd';
import { isEmpty } from 'lodash';

import './assets/style.css';

// Import actions
import { updateUserProfile } from '../../redux/actions/userActions';
import CitySearch from '../search/CitySearch';

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const Profile = ({
  user,
  form: { getFieldDecorator, validateFieldsAndScroll },
  updateUserProfile,
}) => {
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    uid: '',
    username: '',
    email: '',
    firstName: '',
    lastName: '',
    companyName: '',
    companyWebsite: '',
    jobTitle: '',
    location: {},
    gitHub: '',
    bitBucket: '',
    gitLab: '',
    twitter: '',
    linkedIn: '',
    facebook: '',
    angelList: '',
    bio: '',
  });

  const {
    uid = '',
    username = '',
    email = '',
    firstName = '',
    lastName = '',
    companyName = '',
    companyWebsite = '',
    jobTitle = '',
    location = '',
    gitHub = '',
    bitBucket = '',
    gitLab = '',
    twitter = '',
    linkedIn = '',
    facebook = '',
    angelList = '',
    bio = '',
  } = userData;

  useEffect(() => {
    if (user) setUserData(user);
  }, [user]);

  const handleSubmit = e => {
    e.preventDefault();
    validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        setLoading(true);
        await updateUserProfile(values);
        // await firestore()
        //   .doc(`users/${uid}`)
        //   .set({ ...values }, { merge: true });
        message.success('Profile updated');
        setLoading(false);
      }
      setLoading(false);
    });
  };

  getFieldDecorator('uid', { initialValue: uid });

  return (
    <Card className="profile-form" bordered={false} loading={loading}>
      <Form {...formItemLayout} onSubmit={handleSubmit} noValidate>
        <Row gutter={24}>
          <Col lg={12}>
            <Form.Item label="Email">
              {getFieldDecorator('email', {
                initialValue: email,
                rules: [
                  {
                    required: true,
                    message: 'Your email is required',
                  },
                ],
              })(<Input type="email" placeholder="Add your email" />)}
            </Form.Item>
            {/* <Form.Item label="EDN username">
              {getFieldDecorator('username', {
                initialValue: username,
                rules: [
                  {
                    required: true,
                    message: 'Your username is required',
                  },
                ],
              })(<Input type="text" placeholder="Add your username" />)}
            </Form.Item> */}
            <Form.Item label="First name">
              {getFieldDecorator('firstName', {
                initialValue: firstName,
                rules: [
                  {
                    required: true,
                    message: 'Your first name is required',
                  },
                ],
              })(<Input type="text" placeholder="Add your first name" />)}
            </Form.Item>
            <Form.Item label="Last name">
              {getFieldDecorator('lastName', {
                initialValue: lastName,
                rules: [
                  {
                    required: true,
                    message: 'Your last name is required',
                  },
                ],
              })(<Input type="text" placeholder="Add your last name" />)}
            </Form.Item>

            <Form.Item label="Location">
              {getFieldDecorator('location', {
                initialValue: location,
                rules: [
                  {
                    required: true,
                    message: 'Your location is required',
                  },
                  {
                    validator: (rule, value, callback) => {
                      const { city, latitude, longitude } = value;
                      if (city && latitude && longitude) {
                        callback();
                        return;
                      }
                      callback('Please select a valid address');
                    },
                  },
                ],
              })(<CitySearch />)}
            </Form.Item>

            <Form.Item label="Company name">
              {getFieldDecorator('companyName', {
                initialValue: companyName,
              })(<Input type="text" placeholder="Add your company name" />)}
            </Form.Item>
            <Form.Item label="Company website">
              {getFieldDecorator('companyWebsite', {
                initialValue: companyWebsite,
              })(<Input type="text" placeholder="Add your company website" />)}
            </Form.Item>

            <Form.Item label="Job title">
              {getFieldDecorator('jobTitle', {
                initialValue: jobTitle,
              })(<Input type="text" placeholder="Add your job title" />)}
            </Form.Item>
          </Col>

          {/* End of Column */}

          <Col lg={12}>
            <Form.Item label="GitHub">
              {getFieldDecorator('gitHub', {
                initialValue: gitHub,
              })(<Input type="text" placeholder="Username" />)}
            </Form.Item>
            <Form.Item label="Bitbucket">
              {getFieldDecorator('bitBucket', {
                initialValue: bitBucket,
              })(<Input type="text" placeholder="Username" />)}
            </Form.Item>
            <Form.Item label="Gitlab">
              {getFieldDecorator('gitLab', {
                initialValue: gitLab,
              })(<Input type="text" placeholder="Username" />)}
            </Form.Item>
            <Form.Item label="LinkedIn">
              {getFieldDecorator('linkedIn', {
                initialValue: linkedIn,
              })(<Input type="text" placeholder="LinkedIn URL" />)}
            </Form.Item>
            <Form.Item label="Twitter">
              {getFieldDecorator('twitter', {
                initialValue: twitter,
              })(<Input type="text" placeholder="@username" />)}
            </Form.Item>
            <Form.Item label="Facebook">
              {getFieldDecorator('facebook', {
                initialValue: facebook,
              })(<Input type="text" placeholder="Facebook URL" />)}
            </Form.Item>
            <Form.Item label="AngelList">
              {getFieldDecorator('angelList', {
                initialValue: angelList,
              })(<Input type="text" placeholder="AngelList URL" />)}
            </Form.Item>
            <Form.Item label="Bio">
              {getFieldDecorator('bio', {
                initialValue: bio,
              })(<Input.TextArea type="text" placeholder="Short bio" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row
          style={{
            width: '100%',
            borderTop: '1px solid #e9e9e9',
            marginTop: '32px',
            padding: '10px 0px',
            background: '#fff',
            textAlign: 'right',
          }}
        >
          <Form.Item {...tailFormItemLayout}>
            <Button loading={loading} type="primary" htmlType="submit">
              Save
            </Button>
          </Form.Item>
        </Row>
      </Form>
    </Card>
  );
};

const ProfileForm = Form.create({ name: 'profile_form' })(Profile);

export default connect(
  null,
  { updateUserProfile }
)(ProfileForm);
