import {
  USER_SIGNED_IN,
  USER_SIGNED_OUT,
  USER_PROFILE_FETCHED,
  USER_PROFILE_UPDATED,
  USER_SIGNED_UP,
} from '../actions/types';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case USER_SIGNED_IN:
      return action.user;
    case USER_SIGNED_UP:
      return action.user;
    case USER_SIGNED_OUT:
      return initialState;
    case USER_PROFILE_FETCHED:
      return { ...state, ...action.user };
    case USER_PROFILE_UPDATED:
      return action.user;
    default:
      return state;
  }
};
