import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { Spin } from 'antd';

const RestrictedRoute = ({
  auth,
  component: Component,
  loginPath = '/',
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      auth.authenticating ? (
        <Spin className="loader" size="large" tip="Authenticating..." />
      ) : auth.authenticated ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            ...props,
            pathname: loginPath,
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(RestrictedRoute);
