import React from 'react';
import { PageHeader, Typography } from 'antd';

const content = description => (
  <div className="content">
    <Typography.Paragraph>{description}</Typography.Paragraph>
  </div>
);

const PageTitle = ({ title = '', description = '', show = true }) =>
  show && title ? (
    <div>
      <PageHeader title={title}>
        <div className="wrap">
          <div className="content">{content(description)}</div>
        </div>
      </PageHeader>
    </div>
  ) : null;

export default PageTitle;
