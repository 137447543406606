import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './components/App';
import * as serviceWorker from './serviceWorker';

// Import store
import store from './redux/store';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

/***
 *
 * This is important for firebase hosting to work properly.
 * https://www.reddit.com/r/Firebase/comments/6u9n3a/service_worker_not_fetching_new_content_from/
 * "headers": [ { "source": "service-worker.js", "headers": [ { "key" : "Cache-Control", "value" : "no-cache" } ] }]
 */

serviceWorker.unregister();
