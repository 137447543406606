import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'antd';
import { isEmpty } from 'lodash';

import HomePageLayout from '../layout/HomePageLayout';

const HomePage = ({ user, history }) => {
  return (
    <HomePageLayout>
      <section className="homepage">
        <div className="content">
          <h1>Ethiopians fixing bugs one line at a time together.</h1>
          <div>
            {isEmpty(user) ? (
              <Button
                shape="round"
                size="large"
                onClick={() => history.push('/join')}
              >
                Join the community
              </Button>
            ) : (
              <Button
                shape="round"
                size="large"
                onClick={() => history.push('/profile')}
              >
                Complete your profile
              </Button>
            )}
          </div>
        </div>
      </section>
    </HomePageLayout>
  );
};

const mapStateToProps = state => ({
  user: state.user,
});

export default connect(mapStateToProps)(HomePage);
