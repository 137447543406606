import {
  WINDOW_SIZE_CHANGED,
  MOBILE_VIEW_ACTIVE,
  DESKTOP_VIEW_ACTIVE,
} from './types';

export const windowSizeChanged = windowWidth => ({
  type: WINDOW_SIZE_CHANGED,
  windowWidth,
});

export const setMobileView = () => ({
  type: MOBILE_VIEW_ACTIVE,
});

export const setDesktopView = () => ({
  type: DESKTOP_VIEW_ACTIVE,
});
