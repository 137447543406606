// User
export const USER_SIGNED_IN = 'USER_SIGNED_IN';
export const USER_AUTHENTICATING = 'USER_AUTHENTICATING';
export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const USER_AUTHENTICATION_FAILED = 'USER_AUTHENTICATION_FAILED';
export const USER_SIGNING_OUT = 'USER_SIGNING_OUT';
export const USER_SIGNED_OUT = 'USER_SIGNED_OUT';
export const USER_SIGNED_UP = 'USER_SIGNED_UP';
export const USER_PROFILE_FETCHED = 'USER_PROFILE_FETCHED';
export const USER_PROFILE_UPDATED = 'USER_PROFILE_UPDATED';
export const USER_TOKEN_REFRESHED = 'USER_TOKEN_REFRESHED';
export const USER_EMAIL_VERIFICATION_LINK_SENT =
  'USER_EMAIL_VERIFICATION_LINK_SENT';
export const USER_EMAIL_RESET_LINK_SENT = 'USER_EMAIL_RESET_LINK_SENT';
export const USER_EMAIL_VERIFIED = 'USER_EMAIL_VERIFIED';
export const USER_RESET_CODE_VERIFIED = 'USER_RESET_CODE_VERIFIED';
export const USER_PASSWORD_RESET_CONFIRMED = 'USER_PASSWORD_RESET_CONFIRMED';
export const USER_PASSWORD_RESET_SENT = 'USER_PASSWORD_RESET_SENT';
export const USER_PROFILE_LISTENER_STARTED = 'USER_PROFILE_LISTENER_STARTED';
export const USER_PIN_UPDATED = 'USER_PIN_UPDATED';

// Layout
export const MOBILE_VIEW_ACTIVE = 'MOBILE_VIEW_ACTIVE';
export const DESKTOP_VIEW_ACTIVE = 'DESKTOP_VIEW_ACTIVE';
export const WINDOW_SIZE_CHANGED = 'WINDOW_SIZE_CHANGED';
