import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Import actions
import { startAuthListener } from '../redux/actions/userActions';

// Import styles
import './assets/style.css';

// Import routes
import routeList from './routes';
import RestrictedRoute from './routes/RestrictedRoute';

const guestRoutes = routeList
  .filter(route => !route.restrictedRoute && route.enabled)
  .map(route => (
    <Route
      key={route.to}
      path={route.to}
      component={route.component}
      exact={route.exact}
    />
  ));

const restrictedRoutes = routeList
  .filter(route => route.restrictedRoute && route.enabled)
  .map(route => (
    <RestrictedRoute
      key={route.to}
      path={route.to}
      component={route.component}
      exact={route.exact}
      loginPath="/join"
    />
  ));

const App = ({ startAuthListener }) => {
  useEffect(() => {
    const unsubscribeAuth = startAuthListener();
    return () => {
      if (unsubscribeAuth) {
        unsubscribeAuth();
      }
    };
  }, []);

  return (
    <Router>
      <Switch>{[...guestRoutes, ...restrictedRoutes]}</Switch>
    </Router>
  );
};

export default connect(
  null,
  { startAuthListener }
)(App);
