import React from 'react';
import { Layout, Card } from 'antd';
import { withRouter } from 'react-router-dom';

import './assets/style.css';

import Footer from './Footer';
import PageTitle from './PageTitle';
import NavigationBar from './NavigationBar';

const FullPageLayout = props => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <NavigationBar {...props} />
      <Layout.Content style={{ margin: '24px', background: '#fff' }}>
        <PageTitle {...props} />
        <Card bordered={false}>{props.children}</Card>
      </Layout.Content>
      <Footer {...props} />
    </Layout>
  );
};

export default withRouter(FullPageLayout);
