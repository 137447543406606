import { MOBILE_VIEW_ACTIVE, DESKTOP_VIEW_ACTIVE } from '../actions/types';

const initialState = {
  isDesktopView: true,
  isMobileView: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MOBILE_VIEW_ACTIVE:
      return { isDesktopView: false, isMobileView: true };
    case DESKTOP_VIEW_ACTIVE:
      return { isDesktopView: true, isMobileView: false };
    default:
      return state;
  }
};
