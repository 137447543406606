import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Menu,
  Icon,
  Layout,
  Avatar,
  Dropdown,
  Button,
  Drawer,
  Divider,
} from 'antd';
import { isEmpty, filter } from 'lodash';
import windowSize from 'react-window-size';

import routes from '../routes';
import { auth } from '../../data/firebase';
import { NavLink } from 'react-router-dom';

// Import actions
import {
  setMobileView,
  setDesktopView,
} from '../../redux/actions/layoutActions';
import { userSignedOut } from '../../redux/actions/userActions';

const NavigationBar = ({
  authenticating,
  user,
  layout,
  setMobileView,
  setDesktopView,
  history,
  match,
  userSignedOut,
}) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileMenu, setIsMobileMenu] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState('');

  useEffect(() => {
    setSelectedMenu(match.path);
  }, [match.path]);

  const toggleMobileMenuOpen = () => {
    if (isMobileMenu) setMobileMenuOpen(!mobileMenuOpen);
  };

  useEffect(() => {
    const { isDesktopView, isMobileView } = layout;
    const windowWidth = window.innerWidth;
    setIsMobileMenu(isMobileView);
    if (windowWidth <= 768 && !isMobileView) setMobileView();
    if (windowWidth > 768 && !isDesktopView) setDesktopView();
  }, [layout.isDesktopView, layout.isMobileView, window.innerWidth]);

  const renderUserIconMenu = () => {
    return (
      <div>
        <span style={{ padding: '0 15px' }}>
          <Icon type="notification" style={{ fontSize: 20 }} />
        </span>
        <span style={{ padding: '0 15px' }}>
          <Icon type="message" style={{ fontSize: 20 }} />
        </span>
      </div>
    );
  };

  const menuItems = () =>
    filter(routes, { enabled: true, hasMenu: true, userMenu: false }).map(
      item =>
        item.restrictedRoute && isEmpty(user) ? null : (
          <Menu.Item key={item.to}>{item.name}</Menu.Item>
        )
    );

  const handleMenuClick = e => {
    if (e.key === '/sign-out') {
      auth().signOut();
      userSignedOut();
      history.push('/join');
      return;
    }
    if (e.key && history) history.push(e.key);
  };

  const userMenuItems = () =>
    filter(routes, { enabled: true, userMenu: true }).map(item =>
      item.restrictedRoute && isEmpty(user) ? null : (
        <Menu.Item key={item.to}>{item.name}</Menu.Item>
      )
    );

  const userMenu = () => {
    return (
      <Menu
        style={{
          lineHeight: '64px',
          width: '100%',
        }}
        onClick={handleMenuClick}
        selectedKeys={[selectedMenu]}
      >
        {userMenuItems()}
        <Menu.Item key="/sign-out">Sign out</Menu.Item>
      </Menu>
    );
  };

  const renderUserAvatar = () => {
    if (isEmpty(user))
      return (
        <span style={{ cursor: 'pointer' }}>
          <Avatar>
            <Icon type="user" />
          </Avatar>
          <span style={{ margin: '0 10px' }}>Cool EDN Dev</span>
        </span>
      );

    return (
      <span style={{ cursor: 'pointer' }}>
        {!user.photoURL ? (
          !isEmpty(user.displayName) && (
            <Avatar>{user.displayName.charAt(0)}</Avatar>
          )
        ) : (
          <Avatar src={user.photoURL} />
        )}
        <span style={{ margin: '0 10px' }}>{user.displayName}</span>
      </span>
    );
  };

  const renderUserDropdown = () => {
    if (isEmpty(user)) return;

    return (
      <Dropdown overlay={userMenu()} placement="bottomRight">
        {renderUserAvatar()}
      </Dropdown>
    );
  };

  const renderLogo = () => {
    return (
      <NavLink to="/">
        {isMobileMenu ? (
          <div className="logo-mobile" />
        ) : (
          <div className="logo" />
        )}
      </NavLink>
    );
  };

  return (
    <div>
      <Drawer
        title={renderUserAvatar()}
        placement="left"
        closable={false}
        onClose={() => toggleMobileMenuOpen()}
        visible={mobileMenuOpen}
      >
        {!isEmpty(user) && (
          <div>
            {userMenu()}

            <Divider />
          </div>
        )}
        <Menu mode="vertical" theme="light" onClick={handleMenuClick}>
          {menuItems()}
        </Menu>
      </Drawer>
      <Layout.Header
        style={{
          width: '100%',
          background: '#fff',
          padding: isMobileMenu ? '0' : '0 24px',
          height: '65px',
        }}
      >
        {renderLogo()}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
            }}
          >
            <Menu
              theme="light"
              mode="horizontal"
              style={{ lineHeight: '64px' }}
              selectedKeys={[selectedMenu]}
              onClick={handleMenuClick}
            >
              {!isMobileMenu && menuItems()}
            </Menu>
          </div>
          <div
            style={{
              display: 'flex',
            }}
          >
            {isEmpty(user) && !authenticating && (
              <div>
                <Button
                  size="large"
                  type="primary"
                  onClick={() => history.push('/join')}
                >
                  Register/Log in
                </Button>
              </div>
            )}

            {!isEmpty(user) && renderUserIconMenu()}

            {isMobileMenu ? (
              <div style={{ padding: '0 24px' }}>
                <Button
                  icon={mobileMenuOpen ? 'menu-fold' : 'menu-unfold'}
                  onClick={e => {
                    e.preventDefault();
                    toggleMobileMenuOpen();
                  }}
                />
              </div>
            ) : (
              renderUserDropdown()
            )}
          </div>
        </div>
      </Layout.Header>
    </div>
  );
};

const mapStateToProps = state => ({
  layout: state.layout,
  user: state.user,
  authenticating: state.auth.authenticating,
});

export default windowSize(
  connect(
    mapStateToProps,
    { setMobileView, setDesktopView, userSignedOut }
  )(NavigationBar)
);
