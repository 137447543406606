import React, { Component } from 'react';
import { Card, Typography } from 'antd';

import FullPageLayout from '../layout/FullPageLayout';

const { Title, Paragraph } = Typography;
class AboutPage extends Component {
  render() {
    return (
      <FullPageLayout
        showTitle
        title="About Ethiopian Developers Network"
        description=""
      >
        <Card bordered={false} style={{ textAlign: 'center' }}>
          <Typography>
            <Title level={3}>Welcome to Ethiopian Developers Network</Title>
            <Paragraph>Ethiopian Developers are cool</Paragraph>
            <Paragraph>¯\_(ツ)_/¯</Paragraph>
            <Paragraph />
          </Typography>
        </Card>
      </FullPageLayout>
    );
  }
}

export default AboutPage;
