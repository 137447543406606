import React from 'react';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';

import './assets/style.css';

import Footer from './Footer';
import NavigationBar from './NavigationBar';

const HomePageLayout = props => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <NavigationBar {...props} />
      <Layout.Content>{props.children}</Layout.Content>
      <Footer {...props} />
    </Layout>
  );
};

export default withRouter(HomePageLayout);
