import React from 'react';
import { Layout } from 'antd';

const Footer = props => {
  return (
    <Layout.Footer style={{ textAlign: 'center' }}>
      Copyright © 2019 • Ethiopian Developers Network
    </Layout.Footer>
  );
};

export default Footer;
