import React from 'react';
import { Avatar, Icon } from 'antd';
import { isEmpty } from 'lodash';

const AVATAR_SIZE = 150;

const UserAvatar = ({ user }) =>
  user && user.uid ? (
    !user.photoURL ? (
      !isEmpty(user.displayName) && (
        <div style={{ textAlign: 'center' }}>
          <Avatar size={AVATAR_SIZE}>{user.displayName.charAt(0)}</Avatar>
        </div>
      )
    ) : (
      !isEmpty(user.photoURL) && (
        <div style={{ textAlign: 'center' }}>
          <Avatar size={AVATAR_SIZE} src={user.photoURL} />
        </div>
      )
    )
  ) : (
    <div style={{ textAlign: 'center' }}>
      <Avatar size={AVATAR_SIZE}>
        <Icon style={{ fontSize: 60 }} type="user" />
      </Avatar>
    </div>
  );

export default UserAvatar;
