import { combineReducers } from 'redux';

import { USER_SIGNED_OUT } from '../actions/types';

// Import reducers
import auth from './auth';
import user from './user';
import layout from './layout';

const rootReducer = combineReducers({
  auth,
  user,
  layout,
});

// Handy trick to clear store upon user sign out.
export default (state, action) =>
  action.type === USER_SIGNED_OUT
    ? rootReducer({}, action)
    : rootReducer(state, action);
