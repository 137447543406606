import React from 'react';
import FullPageLayout from '../layout/FullPageLayout';

const SettingsPage = props => {
  return <FullPageLayout>User Settings</FullPageLayout>;
};

SettingsPage.propTypes = {};

export default SettingsPage;
