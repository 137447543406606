/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import {
  InstantSearch,
  Configure,
  connectHits,
  connectStats,
  connectSearchBox,
  connectPagination,
  connectRefinementList,
} from 'react-instantsearch-dom';
import {
  List,
  Card,
  Avatar,
  Input,
  Row,
  Col,
  Divider,
  Pagination,
  Checkbox,
} from 'antd';
import { isEmpty, truncate } from 'lodash';
import md5 from 'md5';

import './assets/css/user-search.css';

const ALGOLIA_APP_ID = process.env.REACT_APP_ALGOLIA_ID || '';
const ALGOLIA_API_KEY = process.env.REACT_APP_ALGOLIA_KEY || '';
const HITS_PER_PAGE = 10;

let hitCount = 0;

const UserStats = ({ processingTimeMS, nbHits }) => {
  hitCount = nbHits;
  return (
    <p>
      Found {nbHits} results in {processingTimeMS}
      ms
    </p>
  );
};

const UserPagination = ({
  currentRefinement,
  nbPages,
  refine,
  createURL,
  totalPages,
}) => {
  return (
    <Pagination
      size="small"
      hideOnSinglePage
      pageSize={HITS_PER_PAGE}
      onChange={(page, pageSize) => {
        refine(page);
      }}
      defaultCurrent={currentRefinement}
      total={hitCount}
    />
  );
};

const UserRefinement = ({
  items,
  isFromSearch,
  refine,
  searchForItems,
  createURL,
}) => {
  return (
    <Row>
      {items.map(item => (
        <Col key={item.label}>
          <Checkbox
            key={item.label}
            onChange={event => {
              refine(item.value);
            }}
          >
            {item.label} ({item.count})
          </Checkbox>
        </Col>
      ))}
    </Row>
  );
};

const UserSearchBox = ({ refine }) => {
  return (
    <div style={{ margin: '20px 0' }}>
      <Input.Search
        size="large"
        placeholder="Search for developers..."
        suffix={
          <img
            style={{ width: '80px', padding: '10px' }}
            src={require('./assets/images/logo-algolia-nebula-blue-full.png')}
          />
        }
        allowClear
        onChange={e => {
          refine(e.target.value);
        }}
      />
    </div>
  );
};

const UserHits = ({ hits }) => {
  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 2,
        md: 2,
        lg: 2,
        xl: 3,
        xxl: 4,
      }}
      dataSource={hits}
      renderItem={item => {
        const avatarUrl =
          item.photoURL ||
          `https://www.gravatar.com/avatar/${md5(item.email)}?s=400&d=wavatar`;

        return (
          <List.Item>
            <Card
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '10px',
              }}
              bordered={true}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >
                <Avatar src={avatarUrl} size={60} />
                <div style={{ textAlign: 'center', margin: '10px 0' }}>
                  {`${item.firstName} ${item.lastName}`}
                </div>
              </div>
              <Card.Meta
                description={
                  <div style={{ textAlign: 'center', margin: '10px 0' }}>
                    {!isEmpty(item.location) ? (
                      item.location.city
                    ) : (
                      <span>&nbsp;</span>
                    )}
                    <div className="stack-list">
                      {truncate(
                        item.stack
                          ? item.stack.map(stack => stack).join(', ')
                          : ' '
                      )}
                    </div>
                  </div>
                }
              />
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

const CustomSearchBox = connectSearchBox(UserSearchBox);
const CustomHits = connectHits(UserHits);
const CustomStats = connectStats(UserStats);
const CustomRefinement = connectRefinementList(UserRefinement);
const CustomPagination = connectPagination(UserPagination);

const UserSearch = props => {
  return (
    <InstantSearch
      appId={ALGOLIA_APP_ID}
      apiKey={ALGOLIA_API_KEY}
      indexName="users"
    >
      <Row gutter={24}>
        <Col>
          <CustomSearchBox />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col lg={6} md={24}>
          <h3>Location</h3>
          <CustomRefinement attribute="location.city" />
          <Divider />
          <h3>Stack</h3>
          <CustomRefinement attribute="stack" />
          <Divider />
        </Col>
        <Col lg={18} md={24}>
          <Row>
            <CustomStats />
          </Row>

          <CustomHits />
        </Col>
      </Row>
      <Row gutter={24}>
        <Col lg={6} md={24} />
        <Col lg={18} md={24}>
          <CustomPagination />
        </Col>
      </Row>
      <Configure hitsPerPage={HITS_PER_PAGE} />
    </InstantSearch>
  );
};

export default UserSearch;
