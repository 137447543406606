import React from 'react';

import FullPageLayout from '../layout/FullPageLayout';
import Profile from '../profile/Profile';

const ProfilePage = props => {
  return (
    <FullPageLayout
      showTitle
      title="Your Profile"
      description="Details about your EDN profile"
    >
      <Profile />
    </FullPageLayout>
  );
};

export default ProfilePage;
