import React from 'react';
import FullPageLayout from '../layout/FullPageLayout';
import UserSearch from '../search/UserSearch';

const DevelopersPage = props => {
  return (
    <FullPageLayout>
      <UserSearch />
    </FullPageLayout>
  );
};

export default DevelopersPage;
