import React, { useState, useEffect, forwardRef } from 'react';
import { Icon, Input } from 'antd';

// Import React Script Library to load Google object
import Script from 'react-load-script';

import './assets/css/location-search.css'; // To disable any eslint 'google not defined' errors

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;

// Initialize Google Autocomplete
/*global google*/
let autocomplete = null;

const CitySearch = forwardRef((props, ref) => {
  const [location, setLocation] = useState({
    latitude: 0.0,
    longitude: 0.0,
    city: '',
  });

  useEffect(() => {
    const { value } = props;
    if (value) setLocation(value);
  }, [props.value.city]);

  const handleScriptLoad = () => {
    autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      {
        types: ['(cities)'],
      }
    );
    autocomplete.addListener('place_changed', handlePlaceSelect);
  };

  const handlePlaceSelect = () => {
    const addressObject = autocomplete.getPlace();

    const latitude = addressObject.geometry.location.lat();
    const longitude = addressObject.geometry.location.lng();
    const city = addressObject.formatted_address;

    if (props.onChange) {
      props.onChange({ latitude, longitude, city });
    } else {
      setLocation({ latitude, longitude, city });
    }
  };

  return (
    <div>
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`}
        onLoad={handleScriptLoad}
      />
      <Input
        id="autocomplete"
        value={location.city}
        placeholder="Where are you located?"
        onChange={e => {
          if (props.onChange) {
            props.onChange({ city: e.target.value });
          } else {
            setLocation({ city: e.target.value });
          }
        }}
        onError={error => console.log(error)}
        suffix={<Icon type="search" className="location-category-icon" />}
      />
    </div>
  );
});

export default CitySearch;
