import React from 'react';

import FullPageLayout from '../layout/FullPageLayout';
import Signin from '../auth/Signin';

const JoinPage = props => {
  return (
    <FullPageLayout>
      <Signin />
    </FullPageLayout>
  );
};

export default JoinPage;
